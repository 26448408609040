import React from 'react'
import { FormattedMessage } from 'react-intl'

import formatDate from '../utils/formatDate'

export default {
  componentType: 'search',
  objectName: 'group',
  sortLevel: 2,
  defaultSorting: '-created_at',
  url: '/search/?filter=group',
  title: 'groupsTable',
  getUrl: data => '/groups/' + data.id,
  table: [
    // {
    //   title: 'empty',
    //   width: 50,
    //   fixed: true,
    //   disableLink: true,
    //   getValue: data => {
    //     const validationWarnings = data.metadata && data.metadata.validationWarnings ? 
    //       data.metadata.validationWarnings : null

    //     return <ValidationWarning validationWarnings={validationWarnings} />
    //   }
    // },
    {
      title: 'groups.name',
      width: 300,
      fixed: true,
      sortBy: 'name',
      getValue: data => data.defaultNameLang === 'ru' ? data.nameRu : data.nameEn
    },
    {
      title: 'code',
      width: 100,
      sortBy: 'slug',
      getValue: data => data.slug
    },
    {
      title: 'groups.birthdate',
      width: 150,
      fixed: true,
      sortBy: 'date_of_birth',
      getValue: data => formatDate(data.dateOfBirth)
    },
    {
      title: 'groups.birthplace',
      width: 120,
      sortBy: 'place_of_birth.name',
      filterBy: 'place',
      filterMode: 'multiple',
      getValue: data => data.placeOfBirth ? data.placeOfBirth.name : null
    },
    {
      title: 'groups.members',
      width: 100,
      sortBy: 'members_count',
      getValue: data => data.membersCount
    },
    {
      title: 'events',
      width: 100,
      sortBy: 'rel_event_person_count',
      getValue: data => data.relEventPersonCount
    },
    {
      title: 'documents',
      width: 100,
      sortBy: 'rel_person_document_count',
      getValue: data => data.relPersonDocumentCount
    },
    {
      title: 'groups.bio',
      width: 200,
      sortBy: 'bio',
      getValue: data => data.bio
    },
    {
      title: 'reviewedByEditor',
      width: 175,
      sortBy: 'reviewed_by_editor',
      filterBy: 'reviewed_by_editor',
      filterOptions: [
        {
          id: 'true',
          name: <FormattedMessage id="yes" />
        },
        {
          id: 'false',
          name: <FormattedMessage id="no" />
        }
      ],
      getValue: person => person.reviewedByEditor ?
        <FormattedMessage id="yes" /> :
        <FormattedMessage id="no" />
    },
    {
      title: 'needsVerificationByEditor',
      width: 175,
      sortBy: 'needs_verification_by_editor',
      filterBy: 'needs_verification_by_editor',
      filterOptions: [
        {
          id: 'true',
          name: <FormattedMessage id="yes" />
        },
        {
          id: 'false',
          name: <FormattedMessage id="no" />
        }
      ],
      getValue: person => person.needsVerificationByEditor ?
        <FormattedMessage id="yes" /> :
        <FormattedMessage id="no" />
    },
    {
      title: 'individuals.table.createdAt',
      width: 120,
      sortBy: 'created_at',
      getValue: data => formatDate(data.createdAt)
    },
    {
      title: 'individuals.table.createdBy',
      width: 165,
      sortBy: 'created_by',
      filterBy: 'user?filter[created_type]=group',
      filterMode: 'multiple',
      getValue: data => data.createdBy
    }
  ]
}
