import React from 'react'
import { FormattedMessage } from 'react-intl'

import formatDate from '../utils/formatDate'

export default {
  componentType: 'search',
  objectName: 'periodical',
  sortLevel: 5,
  defaultSorting: '-created_at',
  url: '/search/?filter=periodical',
  title: 'periodicalsTable',
  getUrl: data => '/periodicals/' + data.id,
  table: [
    // {
    //   title: 'empty',
    //   width: 50,
    //   fixed: true,
    //   disableLink: true,
    //   getValue: data => {
    //     const validationWarnings = data.metadata && data.metadata.validationWarnings ? 
    //       data.metadata.validationWarnings : null

    //     return <ValidationWarning validationWarnings={validationWarnings} />
    //   }
    // },
    {
      title: 'periodicals.kind',
      width: 150,
      fixed: true,
      sortBy: 'kind.name',
      filterBy: 'kind?filter[id][prefix]=document.periodical',
      filterMode: 'multiple',
      getValue: data => data.kind ? data.kind.name : null
    },
    {
      title: 'periodicals.title',
      width: 300,
      fixed: true,
      sortBy: 'name',
      getValue: data => data.name
    },
    {
      title: 'inv',
      width: 100,
      sortBy: 'slug',
      getValue: data => data.slug
    },
    {
      title: 'periodicals.language',
      width: 150,
      sortBy: 'languages.name',
      getValue: data => data.languages
        ? data.languages.map(l => l.name).join(', ')
        : null
    },
    {
      title: 'periodicals.publisher',
      width: 150,
      sortBy: 'publishers.name',
      getValue: data => {
        if (!data.publishers || !Array.isArray(data.publishers)) return null
        return data.publishers.map(publisher => publisher.name).join(', ')
      }
    },
    {
      title: 'periodicals.frequency',
      width: 200,
      sortBy: 'periodical_frequency.name',
      getValue: data => data.periodicalFrequency ? data.periodicalFrequency.name : null
    },
    {
      title: 'periodicals.year-from',
      width: 100,
      sortBy: 'date_from',
      filterBy: 'date_from?filter[have_public_records]=periodical',
      getValue: data => formatDate(data.dateFrom)
    },
    {
      title: 'periodicals.year-to',
      width: 100,
      sortBy: 'date_to',
      getValue: data => formatDate(data.dateTo)
    },
    {
      title: 'periodicals.table.city',
      width: 120,
      sortBy: 'place.name',
      filterBy: 'place',
      filterMode: 'multiple',
      getValue: data => data.place ? data.place.name : null
    },
    {
      title: 'periodicals.people',
      width: 100,
      sortBy: 'rel_person_document_count',
      getValue: data => data.relPersonDocumentCount
    },
    {
      title: 'periodicals.published',
      width: 105,
      sortBy: 'published_on_web.name',
      filterBy: 'published_on_web',
      getValue: data => data.publishedOnWeb ? data.publishedOnWeb.name : null
    },
    {
      title: 'reviewedByEditor',
      width: 175,
      sortBy: 'reviewed_by_editor',
      filterBy: 'reviewed_by_editor',
      filterOptions: [
        {
          id: 'true',
          name: <FormattedMessage id="yes" />
        },
        {
          id: 'false',
          name: <FormattedMessage id="no" />
        }
      ],
      getValue: data => data.reviewedByEditor ?
        <FormattedMessage id="yes" /> :
        <FormattedMessage id="no" />
    },
    {
      title: 'needsVerificationByEditor',
      width: 175,
      sortBy: 'needs_verification_by_editor',
      filterBy: 'needs_verification_by_editor',
      filterOptions: [
        {
          id: 'true',
          name: <FormattedMessage id="yes" />
        },
        {
          id: 'false',
          name: <FormattedMessage id="no" />
        }
      ],
      getValue: data => data.needsVerificationByEditor ?
        <FormattedMessage id="yes" /> :
        <FormattedMessage id="no" />
    },
    {
      title: 'periodicals.createdAt',
      width: 120,
      sortBy: 'created_at',
      getValue: data => formatDate(data.createdAt)
    },
    {
      title: 'individuals.table.createdBy',
      width: 165,
      sortBy: 'created_by',
      filterBy: 'user?filter[created_type]=periodical',
      filterMode: 'multiple',
      getValue: data => data.createdBy
    }
  ]
}
