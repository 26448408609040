import React from 'react'
import { FormattedMessage } from 'react-intl'

import ValidationWarning from '../components/ValidationWarning'
import formatDate from '../utils/formatDate'

export default {
  title: 'search',
  componentType: 'search',
  endpoint: '/search',
  defaultSorting: '-created_at',
  url: '/search/(:term)',
  endpointParams: {
    'page[size]': 100,
    'page[number]': 1
  },
  table: [
    {
      title: 'empty',
      width: 50,
      fixed: true,
      sortBy: 'has_validation_warnings',
      filterTitle: 'validation-warnings',
      filterBy: 'has_validation_warnings',
      filterOptions: [
        {
          id: 'true',
          name: <FormattedMessage id="yes" />
        },
        {
          id: 'false',
          name: <FormattedMessage id="no" />
        }
      ],
      getValue: data => {
        const validationWarnings = data.metadata && data.metadata.validationWarnings ?
          data.metadata.validationWarnings : null

        return <ValidationWarning validationWarnings={validationWarnings} />
      }
    },
    {
      title: 'search.table.type',
      width: 150,
      fixed: true,
      getValue: data => data.type
    },
    {
      title: 'search.table.title',
      width: 400,
      fixed: true,
      sortBy: 'name',
      getValue: data => data.title
    },
    {
      title: 'code-inv',
      width: 200,
      sortBy: 'slug',
      getValue: data => data.slug
    },
    {
      title: 'collection',
      width: 350,
      sortBy: 'collection.name',
      filterBy: 'collection?page[size]=all&filter[relationships][institution][id]=all&sort=-documents_count_including_unpublished',
      filterMode: 'multiple',
      getValue: data => data.collection ? data.collection.name : null
    },
    {
      title: 'institution',
      width: 180,
      sortBy: 'institution.code',
      filterBy: 'institution?response[type]=short',
      getValue: data => data.institution ? data.institution.code : null
    },
    {
      title: 'place',
      width: 120,
      sortBy: 'place.name',
      filterBy: 'place',
      filterMode: 'multiple',
      getValue: data => {
        if (data.placeOfBirth) {
          return data.placeOfBirth.name
        }

        if (data.place) {
          return data.place.name
        }

        return null
      }
    },
    {
      title: 'published',
      width: 105,
      sortBy: 'published_on_web.name',
      filterBy: 'published_on_web',
      getValue: data => data.publishedOnWeb ? data.publishedOnWeb.name : null
    },
    {
      title: 'createdAt',
      width: 150,
      sortBy: 'created_at',
      filterBy: 'date_from',
      filterMode: 'date',
      getValue: data => formatDate(data.createdAt)
    },
    {
      title: 'individuals.table.createdBy',
      width: 165,
      sortBy: 'created_by',
      filterBy: 'user',
      filterMode: 'multiple',
      getValue: data => data.createdBy
    }
  ]
}
