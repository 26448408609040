import React from 'react'
import { FormattedMessage } from 'react-intl'

import ValidationWarning from '../components/ValidationWarning'
import formatDate from '../utils/formatDate'

export default {
  componentType: 'search',
  objectName: 'book',
  sortLevel: 4,
  defaultSorting: 'draft,-created_at', //-inv_int
  url: '/search/?filter=book',
  title: 'books',
  getUrl: data => '/books/' + data.id,
  table: [
    {
      title: 'empty',
      width: 50,
      fixed: true,
      sortBy: 'has_validation_warnings',
      filterTitle: 'validation-warnings',
      filterBy: 'has_validation_warnings',
      filterOptions: [
        {
          id: 'true',
          name: <FormattedMessage id="yes" />
        },
        {
          id: 'false',
          name: <FormattedMessage id="no" />
        }
      ],
      getValue: data => {
        const validationWarnings = data.metadata && data.metadata.validationWarnings ?
          data.metadata.validationWarnings : null

        return <ValidationWarning validationWarnings={validationWarnings} />
      }
    },
    {
      title: 'books.kind',
      width: 220,
      fixed: true,
      sortBy: 'kind.name',
      filterBy: 'kind?filter[id][prefix]=document.book',
      filterMode: 'multiple',
      getValue: data => data.kind ? data.kind.name : null
    },
    {
      title: 'books.title',
      width: 400,
      fixed: true,
      sortBy: 'name',
      getValue: data => data.name
    },
    {
      title: 'books.inv',
      width: 100,
      sortBy: 'inv_int',
      getValue: data => data.inv
    },
    {
      title: 'institution',
      width: 180,
      sortBy: 'institution.code',
      filterBy: 'institution?response[type]=short',
      getValue: data => data.institution ? data.institution.code : null
    },
    {
      title: 'books.draft',
      width: 150,
      sortBy: 'draft,-created_at',
      getValue: data => data.draft ? 'Да' : 'Нет'
    },
    {
      title: 'accessType',
      width: 180,
      sortBy: 'access_type.name',
      filterBy: 'access_type',
      getValue: data => data.accessType ? data.accessType.name : null
    },
    {
      title: 'books.publisher',
      width: 150,
      sortBy: 'publishers.name',
      getValue: data => {
        if (!data.publishers || !Array.isArray(data.publishers)) return null
        return data.publishers.map(publisher => publisher.name).join(', ')
      }
    },
    {
      title: 'books.year',
      width: 60,
      sortBy: 'date_from',
      filterBy: 'date_from?filter[have_public_records]=book',
      getValue: data => data.dateFrom
    },
    {
      title: 'books.isbn',
      width: 150,
      sortBy: 'isbn',
      getValue: data => data.isbn
    },
    {
      title: 'books.persons',
      width: 90,
      sortBy: 'rel_person_document_count',
      getValue: data => data.relPersonDocumentCount
    },
    {
      title: 'books.published',
      width: 105,
      sortBy: 'published_on_web.name',
      filterBy: 'published_on_web',
      getValue: data => data.publishedOnWeb ? data.publishedOnWeb.name : null
    },
    {
      title: 'books.files',
      width: 60,
      sortBy: 'files_count',
      getValue: data => data.filesCount
    },
    {
      title: 'collection',
      width: 350,
      sortBy: 'collection.name',
      filterBy: 'collection?page[size]=all&filter[relationships][institution][id]=all&sort=-documents_count_including_unpublished',
      filterMode: 'multiple',
      getValue: data => data.collection ? data.collection.name : null
    },
    {
      title: 'books.place',
      width: 120,
      sortBy: 'place.name',
      filterBy: 'place',
      filterMode: 'multiple',
      getValue: data => data.place ? data.place.name : null
    },
    {
      title: 'reviewedByEditor',
      width: 175,
      sortBy: 'reviewed_by_editor',
      filterBy: 'reviewed_by_editor',
      filterOptions: [
        {
          id: 'true',
          name: <FormattedMessage id="yes" />
        },
        {
          id: 'false',
          name: <FormattedMessage id="no" />
        }
      ],
      getValue: data => data.reviewedByEditor ?
        <FormattedMessage id="yes" /> :
        <FormattedMessage id="no" />
    },
    {
      title: 'needsVerificationByEditor',
      width: 175,
      sortBy: 'needs_verification_by_editor',
      filterBy: 'needs_verification_by_editor',
      filterOptions: [
        {
          id: 'true',
          name: <FormattedMessage id="yes" />
        },
        {
          id: 'false',
          name: <FormattedMessage id="no" />
        }
      ],
      getValue: data => data.needsVerificationByEditor ?
        <FormattedMessage id="yes" /> :
        <FormattedMessage id="no" />
    },
    {
      title: 'books.createdAt',
      width: 120,
      sortBy: 'created_at',
      getValue: data => formatDate(data.createdAt)
    },
    {
      title: 'individuals.table.createdBy',
      width: 165,
      sortBy: 'created_by',
      filterBy: 'user?filter[created_type]=book',
      filterMode: 'multiple',
      getValue: data => data.createdBy
    }
  ]
}
