import React from 'react'
import { FormattedMessage } from 'react-intl'

import formatDate from '../utils/formatDate'
import ValidationWarning from '../components/ValidationWarning'

export default {
  componentType: 'search',
  objectName: 'event',
  sortLevel: 3,
  defaultSorting: '-created_at',
  url: '/search/?filter=event',
  title: 'events',
  getUrl: data => '/events/' + data.id,
  table: [
    {
      title: 'empty',
      width: 50,
      fixed: true,
      disableLink: true,
      getValue: data => {
        const validationWarnings = data.metadata && data.metadata.validationWarnings ?
          data.metadata.validationWarnings : null

        return <ValidationWarning validationWarnings={validationWarnings} />
      }
    },
    {
      title: 'events.table.kind',
      width: 230,
      fixed: true,
      sortBy: 'kind.name',
      filterBy: 'kind?filter[id][prefix]=event',
      filterMode: 'multiple',
      getValue: data => data.kind ? data.kind.name : null
    },
    {
      title: 'events.table.title',
      width: 400,
      fixed: true,
      sortBy: 'name',
      getValue: data => data.name
    },
    {
      title: 'code',
      width: 100,
      sortBy: 'slug',
      getValue: data => data.slug
    },
    {
      title: 'events.table.dateFrom',
      width: 120,
      sortBy: 'date_from',
      filterBy: 'date_from?filter[have_public_records]=event',
      filterMode: 'date',
      getValue: data => formatDate(data.dateFrom)
    },
    {
      title: 'events.table.dateTo',
      width: 120,
      sortBy: 'date_to',
      getValue: data => formatDate(data.dateTo)
    },
    {
      title: 'events.members',
      width: 100,
      sortBy: 'rel_event_person_count',
      getValue: data => data.relEventPersonCount
    },
    {
      title: 'events.organisations',
      width: 100,
      sortBy: 'rel_event_organisation_count',
      getValue: data => data.relEventOrganisationCount
    },
    {
      title: 'events.table.city',
      width: 120,
      sortBy: 'place.name',
      filterBy: 'place',
      filterMode: 'multiple',
      getValue: data => data.place ? data.place.name : null
    },
    {
      title: 'reviewedByEditor',
      width: 175,
      sortBy: 'reviewed_by_editor',
      filterBy: 'reviewed_by_editor',
      filterOptions: [
        {
          id: 'true',
          name: <FormattedMessage id="yes" />
        },
        {
          id: 'false',
          name: <FormattedMessage id="no" />
        }
      ],
      getValue: data => data.reviewedByEditor ?
        <FormattedMessage id="yes" /> :
        <FormattedMessage id="no" />
    },
    {
      title: 'needsVerificationByEditor',
      width: 175,
      sortBy: 'needs_verification_by_editor',
      filterBy: 'needs_verification_by_editor',
      filterOptions: [
        {
          id: 'true',
          name: <FormattedMessage id="yes" />
        },
        {
          id: 'false',
          name: <FormattedMessage id="no" />
        }
      ],
      getValue: data => data.needsVerificationByEditor ?
        <FormattedMessage id="yes" /> :
        <FormattedMessage id="no" />
    },
    {
      title: 'events.createdAt',
      width: 120,
      sortBy: 'created_at',
      getValue: data => formatDate(data.createdAt)
    },
    {
      title: 'individuals.table.createdBy',
      width: 165,
      sortBy: 'created_by',
      filterBy: 'user?filter[created_type]=event',
      filterMode: 'multiple',
      getValue: data => data.createdBy
    }
  ]
}
