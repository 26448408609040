import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { injectIntl } from 'react-intl'
import Spin from 'antd/lib/spin'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Button from './Button'
import camelCase from 'lodash/camelCase'
import getTableConfig from '../utils/getTableConfigByType'
import config from '../config'
import url from 'url'
import LabelWrapper from './LabelWrapper'
import Select from 'antd/lib/select'
import { get } from '../redux/actions/apiActions'
import getRequestDataByUrl from '../utils/getRequestDataByUrl'
import DateSelector from './DateSelector'
import FullDateSelector from './FullDateSelector'
import debounce from 'lodash/debounce'

const Option = Select.Option

const searchConfig = config.pages.searchTable

class FiltersForm extends PureComponent {

  constructor() {
    super()
    this.state = {
      isOpen: false,
      filters: {},
      relFilterByToSearchUrl: {}
    }
    this.handleSelectSearch = debounce(this.handleSelectSearchRaw, 300).bind(this)
  }

  handleSelectSearchRaw(filterBy, value) {
    if (value == null || value.trim() === '') {
      this.setState({ relFilterByToSearchUrl: {} })
      return
    }

    const urlObj = url.parse(filterBy)
    const searchUrl = urlObj.pathname + '?' +
      (urlObj.query ? urlObj.query + '&' : '') + 'filter[name][infix]=' + value

    this.setState({ relFilterByToSearchUrl: { [filterBy]: searchUrl } })
    this.props.dispatch(get('/' + searchUrl))
  }

  loadFilterOptions(filterBy) {
    this.props.dispatch(get('/' + filterBy))
  }

  isLoadingOptions(filterBy) {
    const url = '/' + filterBy
    let loading = false

    if (this.props.data.meta[url]) {
      const endPointKeys = Object.keys(this.props.data.meta[url])
      endPointKeys.forEach(item => {
        if (this.props.data.meta[url][item].loading) {
          loading = true
        }
      })
    }

    return loading
  }

  getFilterKey(item, type) {
    let key = ''

    if (['collection', 'published_on_web', 'access_type', 'institution', 'place'].indexOf(type) >= 0) {
      key = `filter[relationships][${type}][id]`
    } else if (['kind'].indexOf(type) >= 0) {
      key = `filter[${item.filterKind ? item.filterKind : type}][prefix]`
    } else if ('date_from' === type) {
      key = [`filter[${type}][ge]`, `filter[${type}][le]`]
    } else if (['user'].indexOf(type) >= 0) {
      key = `filter[${item.filterKind ? item.filterKind : 'created_by'}][id]`
    } else {
      key = `filter[${type}]`
    }

    return key
  }

  onSelect(item, type, value) {
    const filterKey = this.getFilterKey(item, type)

    let filters = {}

    if ('date_from' === type) {
      filters = { [filterKey[0]]: value[0], [filterKey[1]]: value[1] }
    } else {
      filters = { [filterKey]: value }
    }

    this.setState({
      filters: {
        ...this.state.filters,
        ...filters
      }
    })
  }

  renderItem(item) {
    const type = url.parse(item.filterBy).pathname
    const filterKey = this.getFilterKey(item, type)

    const searchFilterBy = this.state.relFilterByToSearchUrl[item.filterBy] ?
      this.state.relFilterByToSearchUrl[item.filterBy] : item.filterBy
    const options = item.filterOptions ?
      item.filterOptions :
      getRequestDataByUrl(this.props.data, '/' + searchFilterBy)
    const isLoading = this.isLoadingOptions(searchFilterBy)

    let component = null
    const spinIcon = <Spin indicator={<Icon type="loading" style={{ fontSize: 24, color: '#30BFB3' }} spin />} />

    if (type === 'date_from' && item.filterMode === 'date') {
      const minmax = options[0] || {}
      component =
        <div>
          <span className="LabelWrapper__text">
            {this.props.intl.formatMessage({ id: item.filterTitle || item.title })}
          </span>
          <FullDateSelector
            minYear={minmax.min}
            maxYear={minmax.max}
            minValue={this.state.filters[filterKey[0]]}
            maxValue={this.state.filters[filterKey[1]]}
            onDateChange={value => this.onSelect(item, type, [value.ge, value.le])}
          />
        </div>
    } else if (type === 'date_from') {
      const minmax = options[0] || {}
      component =
        <div className="LabelWrapper">
          <span className="LabelWrapper__text">
            {this.props.intl.formatMessage({ id: item.filterTitle || item.title })}
          </span>
          <DateSelector
            min={minmax.min}
            max={minmax.max}
            inline
            externalValue
            minValue={this.state.filters[filterKey[0]]}
            maxValue={this.state.filters[filterKey[1]]}
            onDateChange={value => this.onSelect(item, type, [value.ge, value.le])}
          />
        </div>
    } else {
      let notFound = !(options && options[0])
        ? <FormattedMessage id="input.no-results" />
        : false

      if (isLoading) {
        notFound = spinIcon
      }

      let value = this.state.filters[filterKey]
      if (item.filterMode === 'multiple') {
        if (this.state.filters[filterKey]) {
          value = this.state.filters[filterKey].split(',')
        } else {
          value = []
        }
      }

      component =
        <div className="SelectComponent">
          <LabelWrapper label={item.filterTitle || item.title}>
            <Select
              placeholder={<FormattedMessage id="filters.select" />}
              allowClear
              notFoundContent={notFound}
              defaultActiveFirstOption={false}
              onChange={value =>
                this.onSelect(
                  item,
                  type,
                  item.filterMode === 'multiple' ? value.join(',') : value
                )
              }
              style={{ width: '100%' }}
              mode={item.filterMode === 'multiple' ? 'multiple' : 'default'}
              value={value}
              onFocus={() => this.setState({ relFilterByToSearchUrl: {} })}
              showSearch
              onSearch={(value) => this.handleSelectSearch(item.filterBy, value)}
              filterOption={false}
            >
              {options.map(option =>
                <Option key={option.id} value={option.id}>
                  {option.name}
                </Option>
              )}
            </Select>
          </LabelWrapper>
        </div>
    }

    return (
      <div className="CommonField" style={{ width: '100%' }} key={item.filterBy}>
        {component}
      </div>
    )
  }

  render() {
    const { isActiveFilters, filters } = this.props

    const columnsConfig = (this.props.filter == null)
      ? searchConfig.table.filter(item => item.filterBy)
      : getTableConfig(camelCase(this.props.filter)).table.filter(item => item.filterBy)

    if (columnsConfig.length === 0) {
      return <div style={{ flex: "1 1 auto" }} />
    }

    const footerContent = [
      <Button key="cancel" type="inline" onClick={() => this.setState({ isOpen: false })}>
        <FormattedMessage id="cancel" />
      </Button>,
      <Button key="create" onClick={() => {
        this.props.onFilter(this.state.filters)
        this.setState({ isOpen: false })
      }}>
        <FormattedMessage id="filters.apply" />
      </Button>
    ]

    return (
      <>
        <Icon
          type="filter"
          className={"SearchLayout__filters-icon " + (isActiveFilters ? "active" : "")}
          onClick={() => {
            columnsConfig
              .filter(item => !item.filterOptions)
              .forEach(item => {
                const options = getRequestDataByUrl(this.props.data, '/' + item.filterBy)
                if (options.length === 0) {
                  this.loadFilterOptions(item.filterBy)
                }
              })

            this.setState({ isOpen: true, filters: this.props.filters })
          }}
        />
        {isActiveFilters && (
          <span className="TabsComponent__tab-amount">{Object.keys(filters).length}</span>
        )}
        <Modal
          className="CreateCard__modal"
          visible={this.state.isOpen}
          title={<FormattedMessage id={'filters.title'} />}
          onCancel={() => this.setState({ isOpen: false })}
          footer={footerContent}
        >
          <div style={{ marginTop: '20px' }}>
            {columnsConfig.map(item => this.renderItem(item))}
          </div>
        </Modal>
      </>
    )
  }

}

const mapStateToProps = function (state, props) {
  return {
    data: state.data
  }
}

export default connect(mapStateToProps)(injectIntl(FiltersForm))